/*
 * @LastEditors: Murphy
 * @LastEditTime: 2021-06-21 10:13:50
 */
import request from '@/network/request.js'

/* 获取轮播图 */
const getSlide = () => {
  return request({
    url: '/offerSystem/app/slideshow/getSlideshow',
    method: 'GET',
  })
}
/* 更新轮播图 */
const upLoadSlide = (files) => {
  return request({
    url: '/offerSystem/app/slideshow/uploadPic',
    method: 'post',
    data: {
      files,
    },
  })
}

/* 添加轮播图 */
const addSlide = ({ onLine }) => {
  return request({
    url: '/offerSystem/app/slideshow/insertSlideshow',
    method: 'POST',
    data: {
      onLine,
    },
  })
}

/* 删除轮播图 */
const deleteSlide = (id) => {
  var formData = new FormData()
  formData.append('id', id)
  return request.put(`/offerSystem/app/slideshow/deleteSlideshow`, formData)
  /*  return request({
    url: `/offerSystem/app/slideshow/deleteSlideshow`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { id },
  }) */
}

export { getSlide, upLoadSlide, addSlide, deleteSlide }
